<template>
  <div class="page-content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div
            class="
              page-title-box
              d-sm-flex
              align-items-center
              justify-content-between
            "
          >
            <h4 class="mb-sm-0">Pembelian Saham</h4>

            <div class="page-title-right">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item active">Daftar Pembelian Saham</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-header align-items-center d-flex">
              <h4 class="card-title mb-0 flex-grow-1">
                Daftar Pembelian Saham
              </h4>
            </div>

            <div class="card-body">
              <DataTable
                :url="dataTableUrl"
                :columns="columnHeaders"
                :actions="actions"
                :reload="reloadDataTable"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DataTable from "../../components/datatable/DataTable.vue";
import { endpoint } from "../../host";
import moment from "moment";

export default {
  components: {
    DataTable,
  },

  created() {},

  data() {
    return {
      reloadDataTable: false,
      dataTableUrl: endpoint["share.purchase"],
      actions: [
        {
          icon: "bx bx-show",
          color: "btn-info",
          tooltip: "detail",
          onclick: (data) => {
            this.$router.push({
              name: "user.share-purchase-detail",
              params: {
                id: data.id
              },
            });
          },
        },
      ],
      columnHeaders: [
        {
          text: "Tanggal",
          render: (data) => {
            return moment(data.created_at).format("DD MMMM YYYY");
          },
        },
        {
          text: "Pembeli",
          name: "buyer.name",
        },
        {
          text: "Periode",
          name: "share_period.name",
        },
        {
          text: "Slot",
          name: "number_of_slot",
        },
        {
          text: "Total Harga",
          render: (data) => {
            return new Intl.NumberFormat("id-ID", {
              style: "currency",
              currency: "IDR",
            }).format(data.total_price);
          },
        },
        {
          text: "Status",
          render: (data) => {
            switch (data.status) {
              case "Y":
                return '<span class="badge bg-success"">terverifikasi</span>';
              case "N":
                return '<span class="badge bg-danger"">tidak terverifikasi</span>'
              default:
                return '<span class="badge bg-warning"">Menunggu</span>';
            }
          },
        },
      ],
    };
  },
  methods: {},
};
</script>

<style>
</style>